import http from "./http";
export default (routeId) => ({
  async index(filters) {
    return http
      .index("routes", filters)
      .then((json) => ({ routes: json }))
      .catch(async (e) => ({ routes: null, error: await e }));
  },
  async get() {
    return http
      .get("routes", routeId)
      .then((json) => ({ route: json }))
      .catch(async (e) => ({ route: null, error: await e }));
  },
  async create(data) {
    return http
      .post("routes", data)
      .then((json) => ({ route: json }))
      .catch(async (e) => ({ route: null, error: await e }));
  },
  async update(data) {
    return http
      .put("routes", routeId, data)
      .then((json) => ({ route: json }))
      .catch(async (e) => ({ route: null, error: await e }));
  },
  async delete() {
    return http
      .delete("routes", routeId)
      .then((json) => ({ route: json }))
      .catch(async (e) => ({ route: null, error: await e }));
  },
});
