<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <base-input
        label="Nome:"
        hint="Insira o nome da rota"
        v-model="route_.name"
      >
      </base-input>
      <base-input label="Ordem:" v-model="route_.order"></base-input>
      <base-input label="Grupo:" v-model="route_.group"> </base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import RouteService from "../../services/routes.js";

export default {
  props: ["route", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      route_: { ...(this.route || {}) },
      isLoadingSave: false,
    };
  },
  watch: {
    route(v) {
      this.route_ = { ...(v || {}) };
    },
  },
  computed: {
    ModalTitle() {
      return this.IsNew ? "Cadastrar Rota" : "Editar Rota";
    },
    Type() {
      return this.$route.params.type || "private";
    },
    IsNew() {
      return !this.route;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.route ? this.updateRoute() : this.createRoute();
    },
    async createRoute() {
      this.isLoadingSave = true;

      const { route, error } = await RouteService().create({
        ...this.route_,
        type: this.Type,
      });

      if (route) {
        this.$emit("should-update");
        this.$emit("close-modal");
        this.route_ = {};
        ElNotification.success({
          title: route.message,
          position: "bottom-right",
        });
      } else
        ElNotification.error({
          title: "Ocorreu um erro ao cadastrar rota",
          message: error.message,
          position: "bottom-right",
        });

      this.isLoadingSave = false;
    },
    updateRoute() {
      fetch(`${this.$store.state.apiUrl}routes/${this.route_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.route_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar rota",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "UserModal",
};
</script>
<style scoped>
.el-autocomplete {
  margin-top: 5px !important;
  display: block !important;
}
</style>
