<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Rotas Cadastradas</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openRoutesModal()"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="routes"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="order" label="ordem"> </el-table-column>
      <el-table-column prop="group" label="grupo"> </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="client">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openRoutesModal(client.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteRoute(client.row)"
                title="
              Remover rota?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <route-modal
      :showModal="showRouteModal"
      :route="route"
      @close-modal="showRouteModal = false"
      @should-update="fetchRoutes"
    ></route-modal>
  </el-card>
</template>

<script>
import RouteModal from "./modals/Route.vue";
import { maska } from "maska";
import RouteService from "../services/routes.js";
export default {
  name: "Routes",
  components: { RouteModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    clientType: "legal",
    createMask: maska,
    route: null,
    routes: null,
    client: null,
    showRouteModal: false,
  }),
  computed: {
    Type() {
      return this.$route.params.type || "private";
    },
  },
  mounted() {
    this.fetchRoutes();
  },
  watch: {
    Type() {
      this.fetchRoutes();
    },
  },
  methods: {
    openRoutesModal(e) {
      (this.route = e), (this.showRouteModal = true);
    },
    async fetchRoutes() {
      const { routes } = await RouteService().index({
        type: this.Type,
      });

      if (routes) this.routes = routes;

      this.isLoading = false;
    },
    async deleteRoute(_) {
      const { route, error } = await RouteService(_.uid).delete();

      if (route) {
        this.fetchRoutes();
        this.$notify({
          title: "Rota removida com sucesso",
          type: "success",
          position: "bottom-right",
        });
      } else {
        this.$notify({
          title: "Não foi possível remover a rota",
          type: "error",
          message: error.message,
          position: "bottom-right",
        });
      }
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>
